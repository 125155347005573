@import '../theme';
@import 'bootstrap/scss/bootstrap';

.pc {
  margin: map-get($spacers, 4) 0 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &__badge {
    margin-left: map-get($spacers, 2);
  }

  &__options {
    cursor: pointer;
    margin-left: map-get($spacers, 2);
    text-decoration: none !important;
    color: $dark;
    font-size: 1.2rem;
  }
}

.empty-text {
  text-align: center;
  color: $text-muted;
  margin: map-get($spacers, 4);
}

.order {
  margin-bottom: map-get($spacers, 3);
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: $gray-100;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 0 map-get($spacers, 3);

    .info {

      color: $dark !important;

      &__name {
        margin-bottom: map-get($spacers, 1);
        text-transform: capitalize;
      }

      &__ordernum {
        font-size: .8rem;
        margin-bottom: 0;
        color: $text-muted;
      }
    }

  }

  &--new {

    .info {

        font-weight: bold;

        &:after {
          content: "•";
          position: absolute;
          font-size: 1.5rem;
          top: map-get($spacers, 3) - .2rem;
          left: map-get($spacers, 3);
        }
    }

  }
}
