@import '../theme';
@import 'bootstrap/scss/bootstrap';

.archive {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  &:hover {
    background: $gray-100;
  }
}

.info {

  &__name {
    margin-bottom: map-get($spacers, 1);
    text-transform: capitalize;
  }

  &__order {
    margin-bottom: 0;
    font-size: .8rem;
    color: $text-muted;
  }

}