@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&display=swap');

$aquamarine: #00ADB5;
$gray-light: #EEEEEE;
$gray-dark: #393E46;
$gray-darker: #222831;

$primary: $aquamarine;
$danger: #E63946;
$dark: $gray-darker;

$font-family-sans-serif: 'Montserrat', sans-serif;

$border-radius: .8rem;

$input-btn-padding-x: .9rem;
$input-btn-padding-y: .6rem;