@import '../theme';
@import 'bootstrap/scss/bootstrap';

.signin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__left {
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    background: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.promo {
  text-align: center;
  color: $white;
  padding: map-get($spacers, 3);

  &__text {
    font-weight: 100;
    margin-bottom: map-get($spacers, 5);
  }

  &__img {
    width: 50%;
  }
}

.controls {
  color: $primary;
  width: 100%;
  padding: map-get($spacers, 5) * 2;

  &__title {
    margin-bottom: map-get($spacers, $key: 3);
  }

  &__forgot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .controls {
    padding: map-get($spacers, 3);
  }
}

@media (max-width: map-get($grid-breakpoints, 'xl')) {
  .controls {
    &__forgot {
      justify-content: center;
      padding: map-get($spacers, 3) 0;
    }
  }
}