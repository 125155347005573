@import './theme';
@import 'bootstrap/scss/bootstrap';

.otp-toast {
  padding: map-get($spacers, 3);
}

.Toastify__toast {
  font-family: $font-family-sans-serif;
  border-radius: $border-radius;
}

.Toastify__toast--default {
  background: $primary;
}

.Toastify__toast--success {
  background: $success;
}

.Toastify__toast--warning {
  background: $warning;
}

.Toastify__toast--error {
  background: $danger;
}