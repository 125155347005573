@import '../theme';
@import 'bootstrap/scss/bootstrap';

.detail {

  &__title {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    margin-bottom: 0;
  }

  &__delete {
    margin-left: map-get($spacers, 2);
    color: $danger;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__ellapsed {
    text-align: center;
    margin: map-get($spacers, 4) 0;
  }

  &__updated {
    display: block;
    text-align: center;
  }
}

.sms {
  display: flex;
  justify-content: space-between;
  align-items: center;;

  &__user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__avatar {
    height: 50px;
    width: 50px;
    background: $dark;
    color: $primary;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-transform: uppercase;
  }

  &__info {
    margin-left: map-get($spacers, 2);
  }

  &__name {
    font-weight: bold;
    text-transform: capitalize;
  }

  &__phone {
    font-size: .95rem;
    color: $text-muted;
  }
}